




























































import { Component, Vue } from "vue-property-decorator";
import DxDateBox from "devextreme-vue/date-box";
import { ClassReportWizardFormFichajes } from "@/shared/dtos/ClassReportWizardFormFichajes";
import tipos_fichajeModule from "@/store/modules/tipos_fichaje-module";
import fichajes_empleadoModule from "@/store/modules/fichajes_empleado-module";
import { API } from "@/shared/api";
import { UtilsDate } from "@/utils/utils-date";
import datos_empresaModule from "@/store/modules/datos_empresa-module";
@Component({
  components: { DxDateBox },
})
export default class InformesPorFecha extends Vue {
  public form: ClassReportWizardFormFichajes = new ClassReportWizardFormFichajes();
  public html: string = "";

  created() {
    this.form.fecha_desde = UtilsDate.AddDias(new Date(), -30);
    this.form.fecha_hasta = new Date();
    this.form.id_tipo_fichaje = [];
    tipos_fichajeModule.gettipos_fichajes();
    datos_empresaModule.getdatos_empresas();
  }

  get tipos_fichaje() {
    if (this.form.id_tipo_fichaje.length === 0) {
      this.form.id_tipo_fichaje = tipos_fichajeModule.tipos_fichajes.map(
        (x) => x.id
      );
    }
    return tipos_fichajeModule.tipos_fichajes;
  }

  get datos_empresa() {
    return datos_empresaModule.datos_empresas;
  }

  aceptar() {
    fichajes_empleadoModule.GenerarInformePorFecha(this.form).then((x) => {
      window.open(API.webApiBaseRootPath + "" + x);
    });
  }
}
